import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Hatem - Software Engineer',
  lang: 'en',
  description: 'Website for Hatem, a software engineer.'
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Hatem',
  subtitle: "I'm a Software Engineer",
  location: 'Japan',
  cta: 'Know more'
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'A Software Engineer with main focus on web development and a mentor.',
  paragraphTwo:
    'I am passionate about teamwork and thriving in diverse environments. I am outcome-focused and enjoy a productive process. I excel at fostering supportive spaces where teams can flourish. With experience living across Europe, Asia, and Africa, I bring a global perspective and a strong drive for continuous learning to the table.',
  paragraphThree:
    'In my downtime, I prioritize quality moments with family and friends, going for a ride on my motorcycle, playing football, or doing CrossFit. Always interested in talking about psychology and mental well-being.'
  // resume: 'resume.pdf' // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'fiba3x3_scores.png',
    title: 'FIBA 3x3 Scores Web App',
    info: 'Web app for FIBA 3x3 basketball to be used on the venue for tournaments. Supporting realtime scoring and possesion tracking with offline capabilities living up to the term "Basketball never stops." It also provides multiple APIs for 3rd party integrations (e.g. TV network).',
    info2:
      'Built using React, Redux, Typescript, RxJS, CouchDB, Heroku, Terraform and AWS.',
    url: 'https://scores.fiba3x3.com/'
  },
  {
    id: nanoid(),
    img: 'fiba3x3_worldtour.png',
    title: 'FIBA 3x3 World Tour Website',
    info: 'Focuses specifically on the FIBA 3x3 World Tour pro-level competitions, providing a centralized source for teams, players, and fans to access exclusive information, news, and updates. From tournament details and standings to captivating highlights, the platform immerses users in the thrilling world of global 3x3 basketball competition.',
    info2:
      'Server-side rendered website using React, Redux, Typescript, SCSS, AWS.',
    url: 'https://worldtour.fiba3x3.com/'
  },
  {
    id: nanoid(),
    img: 'fiba3x3_website.png',
    title: 'FIBA 3x3 Website',
    info: 'The official website for FIBA 3x3 basketball, offering news, updates, rankings and resources for players, teams, and fans. The platform also serves as a community hub, facilitating entry points for player registration, team formation, and providing educational materials for event organizers, coaches, and referees.',
    info2:
      'Statically-generated website using JAMstack; MetalsmithJS, SCSS and AWS.',
    url: 'https://fiba3x3.com/'
  },
  {
    id: nanoid(),
    img: 'sanoma.png',
    title: 'Sanoma AdManager Web App',
    info: "Creating a web solution for Finland's largest media group, our team engineered a platform to effectively manage ad campaigns and monitor performance metrics. I contributed to the implementation of key features, ensuring seamless campaign creation, management, and tracking capabilities.",
    info2: 'Stack used: VueJS, Tailwind CSS, C#, Azure, and Contentful.',
    url: 'https://admanager.fi/'
  },
  {
    id: nanoid(),
    img: 'shipandco_app.png',
    title: 'Ship&co Web App',
    info: 'SaaS web application of an All-in-One Shipping Management Solution for Global eCommerce. Multilingual built using MeteorJS, MongoDB and has its own REST API offered to users.',
    info2:
      'Deliver the SaaS web app for eCommerce shipping management solution. Handle 4 web applications and websites. Integrate EC platforms, carriers (e.g. Shopify, FedEx, etc.) and Stripe. Mentor multiple interns.',
    url: 'https://app.shipandco.com/'
  },
  {
    id: nanoid(),
    img: 'shipandco_website.png',
    title: 'Ship&co Website',
    info: 'Static website for Ship&co app displaying its features, information and API documentation.',
    info2:
      'Mobile-friendly and lightning fast. Multilingual supporting Japanese, English and French. Built using JAMstack; MetalsmithJS, SCSS and Netlify.',
    url: 'https://www.shipandco.com/en/'
  },
  {
    id: nanoid(),
    img: 'remotework.png',
    title: 'Remotework.jp',
    info: 'I believe remote work should be available to tech employees even before COVID hit. So I contributed by creating this website; a list of tech remote work opportunities to boost the remote work scene in Japan.',
    info2:
      'Open-source project on GitHub. Website is in Japanese and English, built with Jekyll & SCSS supporting Dark and Light modes.',
    url: 'https://remotework.jp/',
    repo: 'https://github.com/remote-jp/remote-in-japan' // if no repo, the button will not show up
  }
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'hatem.houssein@gmail.com',
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/hatemhoussein/'
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/SaulSilver'
    }
  ]
};
